import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKalina as author } from 'data/authors';
import styled from 'styled-components';
import img from 'img/blog/error-rage-clicks/bg.png';
import img1 from 'img/blog/error-rage-clicks/dropdown-menu.png';
import img21 from 'img/blog/error-rage-clicks/dropdown-menu-type-of-error-1.png';
import img22 from 'img/blog/error-rage-clicks/dropdown-menu-type-of-error-2.png';
import img3 from 'img/blog/error-rage-clicks/shorter-way.jpg';
import img4 from 'img/blog/error-rage-clicks/example-from-our-blog.png';
import img5 from 'img/blog/error-rage-clicks/error-clicks-occur.png';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h4 {
    color: #777;
    font-weight: 700;
    font-size: 1rem;
  }
`;

const Content = () => {
  return (
    <Container>
      <p>Frustrating user experience can be improved faster than you think.</p>
      <p>
        If you’re familiar with our guide to{' '}
        <a
          href="https://livesession.io/blog/user-frustration/"
          target="_blank"
          rel="noopener noreferrer"
        >
          understanding user frustration
        </a>
        , you’ve already read a thing or two about rage clicks and error clicks. In this blog post,
        we’re going to cover this topic in more detail. You’ll learn:
      </p>
      <ul>
        <li>what are rage clicks and error clicks</li>
        <li>how you can filter them with session tracking tools like LiveSession</li>
        <li>
          how detecting error and rage clicks can help you spot some of the most important issues on
          your website
        </li>
      </ul>
      <p>Ready to say goodbye to frustration? Let’s dive in!</p>
      <h2>What are rage clicks</h2>
      <p>A situation like this one probably sounds familiar to you:</p>
      <p>
        You’re on a website, trying to complete a goal. You finally found the button you’re looking
        for: <i>Proceed</i>, <i>Buy now</i>, <i>Read more</i> – you name it.
      </p>
      <p>Then, you try to click on it.</p>

      <p>Nothing happens.</p>
      <p>You click again. Still, nothing happens.</p>

      <p>
        <i>Click, click. Click, click, click, click.</i>
      </p>
      <p>A disaster you’d rather avoid, right?</p>
      <p>You get angry and keep clicking even more, even faster. </p>
      <p>
        This is what we call a <strong>rage click</strong>. It happens when a user clicks (or taps)
        on a specific section of a website repeatedly, very fast, in frustration. You could also say
        that it’s a digital parallel of pressing the elevator button a couple of times in a row when
        it’s not responding. Overall, a rage click is a very clear signal that your visitor wasn’t
        able to achieve their goal.
      </p>
      <h2>What are error clicks</h2>
      <p>
        Rather than being related to an emotional reaction, <strong>error clicks</strong> are based
        on technical issues. In LiveSession, we’re talking about an <strong>error click</strong>{' '}
        when a JavaScript error occurs.
      </p>
      <p>
        If you’re not particularly tech-savvy and this term doesn’t sound clear to you, here’s a
        quick explanation:
      </p>
      <p>
        JavaScript (often abbreviated as JS) is one of the most popular programming languages used
        to build web pages. A popular metaphor claims that this language was made to “
        <a href="https://javascript.info/intro" target="_blank" rel="noopener noreferrer">
          make pages alive
        </a>
        ”. When a particular JS element is not working, it means you’re dealing with a JavaScript
        error.
      </p>
      <p>
        In this case, the user doesn’t have to click on something many times in a row. Just one
        click is enough for the tracking tool to detect an error. What’s more, sometimes the users
        don’t even notice that something is broken within the app. Most of the time, however, error
        clicks indicate that you need to prioritize the issue.
      </p>
      <h2>How to filter rage clicks and error clicks with LiveSession</h2>
      <p>
        LiveSession detects error clicks and rage clicks automatically. You can find all the
        sessions with these types of clicks very easily – all you need to do is select a filter from
        the drop-down menu
      </p>
      <img
        src={img1}
        alt="dropdown-menu"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Filter data in menu"
      />
      <p>
        If needed, you can use more advanced filters, too. <strong>Rage clicks</strong> can be
        filtered based on the CSS selector or text, while <strong>error clicks</strong> can also be
        sorted according to a specific type of error:
      </p>
      <img
        src={img21}
        alt="dropdown-menu-type-of-error-1"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Specific type of error"
      />
      <img
        src={img22}
        alt="dropdown-menu-type-of-error-2"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Specific type of error"
      />
      <p>
        If you would like to read more about using filters in LiveSession, our
        <a
          href="https://livesession.io/blog/how-to-use-filters-in-user-research/"
          target="_blank"
          rel="noopener noreferrer"
        >
          guide to user segmentation
        </a>{' '}
        is sure to come in handy.
      </p>
      <h2>How rage clicks and error clicks can help you spot important issues</h2>
      <p>In this part of the article:</p>
      <ul>
        <li>
          we’ll cover some of the most common <strong>appearances of rage and error clicks</strong>{' '}
        </li>
        <li>
          you’ll learn <strong>how to draw conclusions</strong> from the events recorded with a
          session tracking software
        </li>
      </ul>
      <p>
        Let’s dive deeper to find out how to leverage rage click and error clicks in your user
        research strategy!
      </p>
      <h3>When rage clicks occur</h3>
      <p>Some of the most common occurrences for rage clicks include:</p>
      <h4>Dead links</h4>
      <p>
        Rage clicks happen when a text looks like a hyperlink (e.g. it’s in a different color, in
        bold or underlined) but it’s not really clickable. Oftentimes, the user is certain that
        they’re dealing with a link and keep clicking on it, expecting to be redirected to a
        different page.
      </p>
      <h4>Unclear error messages</h4>
      <p>
        When a user is filling a form and they got the postcode format or their credit card number
        wrong, they might not be able to proceed. If the error message is not clear, they will keep
        clicking on the <i>Proceed</i>
        or <i>Send</i> button repeatedly, until they realize that something is wrong, or worse,
        leave the page.
      </p>
      <h4>Desire paths </h4>
      <p>
        Sometimes users are expecting certain elements to act in a certain way (in this case, to be
        clickable), yet the website behaves differently. Still, the user is trying to follow the
        expected path – a <strong>desire path</strong>, also known as goat track or bootleg trail.
        This term is best explained with one of the most popular examples in the history of UX:
      </p>
      <img
        src={img3}
        alt="shorter-way"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Shorter way"
      />
      <p>
        In this case, the user doesn’t want to take the established road and chooses the shortcut
        instead, just because it’s faster and more natural.
      </p>
      <p>
        Desire paths can be based on the expectations, too. For instance, if the user goes on your
        company blog and clicks on the logo of your product in the top left corner, they probably
        expect to be taken back to the main page. If the logo is not clickable, they might try to
        click on it again, and again – and here goes our rage click.
      </p>
      <figure className="img-fluid justify-content-center mb-5 mt-3">
        <img
          src={img4}
          alt="example-from-our-blog"
          className="img-fluid justify-content-center mb-3"
          title="LiveSession blog"
        />
        <figcaption>
          <i>
            An example from our blog – the users are likely to click on the LiveSession logo,
            expecting to be redirected to the home page. If the logo is not linked, they may click
            on it a couple of times, until they realize the pattern is not working.
          </i>
        </figcaption>
      </figure>
      <h4>Speed issues</h4>
      <p>
        A rage click may also indicate that a particular element of your page takes too long to
        load. What’s more, this phenomenon may occur when the entire page is loading and the user
        keeps clicking on the loading screen to see if something will happen. Keep in mind that this
        is more likely to happen if a slow response comes with an <i>unclear message</i> – the user
        gets lost and keeps clicking to get any kind of feedback.
      </p>
      <p>
        Most of the time you can take action to improve the page speed, yet sometimes the fault lies
        somewhere else. In that case, we’re talking about <strong>false rage clicks</strong>.
      </p>
      <h4>False positive rage clicks</h4>
      <p>
        It might happen that a rage click is detected when it’s not related to the interface design.
        We call it a <strong>false positive rage click</strong>, which might be due to a poor
        internet connection or a lagging device. It always pays off to double-check a recording to
        see if a rage click is caused by an external reason or maybe there is something that can be
        improved on your website.
      </p>
      <h4>When error clicks occur</h4>
      <p>
        As mentioned above, error clicks are logged based on JavaScript errors. When the user
        notices that a particular element is broken, they might start clicking on it repeatedly.
        This is why <strong>error clicks are often registered together with rage clicks</strong>,
        like in the example below:
      </p>
      <img
        src={img5}
        alt="error-clicks-occur"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Types of errors"
      />
      <p>
        In such cases, you can see that the visitor was frustrated and you can also see what kind of
        error caused the frustration. When you have complete information handy, it’s much easier to
        start working on the bug.
      </p>
      <h2>Key takeaways</h2>
      <p>
        <strong>Rage clicks and error clicks detection</strong> is a handy feature designed to help
        you spot the most urgent issues as soon as possible. You don’t have to search manually to
        find the main points of frustration. Instead, your session recording app will keep track of
        them automatically. This makes work much smoother for everyone involved, including
        developers, UX designers and customer service agents.
      </p>
      <p>
        If you’re not monitoring the frustration of your users yet, it’s high time you started
        paying more attention. It’s a simple and efficient way to learn more about your visitors and
        improve their experience – and in the end, this is how you make your customers happy and
        loyal.{' '}
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Rage Clicks & Error Clicks – Say Goodbye to Frustration',
  url: '/blog/error-rage-clicks',
  description:
    'If you’re familiar with our guide to understanding user frustration, you’ve already read a thing or two about rage clicks and error clicks.',
  author,
  img,
  imgSocial: img,
  date: '2019-11-06',
  category: '',
  imgStyle: { borderBottom: '1px solid rgba(0,0,0,0.125)' },
  group: 'Articles',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    descriptionSocial="Frustrating user experience can be improved faster than you think."
  />
);
